import NextLink from 'next/link';
import { cloneElement, isValidElement } from 'react';
import { useWorkspaceId } from '~/hooks/use-workspace-id';

export type LinkProps = {
  href: string | { href: string; as: string }
  children: React.ReactNode
  shallow?: boolean
  external?: boolean
  hideQuery?: boolean
  query?: {}
  hash?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  prefetch?: boolean
}

export const Link = ({
  href,
  children,
  query,
  hash,
  hideQuery,
  shallow,
  external,
  prefetch = false,
}: LinkProps) => {
  const workspaceId = useWorkspaceId()

  let linkHref: string
  let linkAs: string
  if (typeof href === 'string') {
    linkHref = href
    linkAs = href.replace('[workspace]', workspaceId!)
  } else {
    linkHref = href.href
    linkAs = href.as.replace('[workspace]', workspaceId!)
  }

  const externalProps = external
    ? {
      target: '_blank',
      rel: 'noreferrer noopener',
    }
    : {}

  return (
    <NextLink style={{}}
      href={{ pathname: linkHref, query, hash }}
      as={{ pathname: linkAs, query: hideQuery ? undefined : query, hash }}
      shallow={shallow}
      passHref
      prefetch={prefetch}
      legacyBehavior>
      {isValidElement(children)
        ? cloneElement(children, { ...externalProps })
        : children}
    </NextLink>
  );
}
